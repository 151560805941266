import logo from './logo.svg';
import './App.css';
import Header from './components/Header.js';
import Form from './components/Form';

function App() {
  return (
    <div>
      <Form/>
    </div>
    
  );
}

export default App;
