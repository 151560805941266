import React, { useState  } from 'react';
import { Transition } from '@headlessui/react'
import wedding from '../wedding.jpg';

import { IconSquareRoundedX, IconTrash, IconSend, IconBrandWhatsapp } from '@tabler/icons-react';

 function Form(){
    const [tamu, setTamu] = useState("");
    const [hasil, setHasil] = useState([]);
    const [link, setLink] = useState("");
    const [popup, setPopup] = useState(false)
    const [selectedData, setSelectedData] = useState(null)
    const [no, setNo] = useState("")
    const [whatsapp, setWhatsapp] = useState([])
    const [bahasa, setBahasa] = useState("")
   
   const deleteById = id => {
    setHasil(oldValues => {
        return oldValues.filter(hasils => hasils !== id)
    })
   }
   const selectBahasa = (e) => {
    setBahasa(e.target.value)
   }

   const detailLink = (id) => {
        const selected = hasil.find(item => item.id === id);
        if (selected) {
            setSelectedData(selected);
            setPopup(true);
           
        } else {
           return;
        }
   }

   function gantiPlusDenganPercent2B(teks) {
    var hasil = "";
    for (var i = 0; i < teks.length; i++) {
      if (teks[i] === "+") {
        hasil += "%2B";
      } else {
        hasil += teks[i];
      }
    }
    return hasil;
  }

   const prosesKirim = (event) =>{
        event.preventDefault();
        setNo('')
        let linkUser = document.getElementById('linkFix').value;
        let linkName = document.getElementById('linkName').value;
   
        // const cekHasil = hasil.map(data => {
        //     return data.link;
        // })
        if(bahasa === ''){
            alert('harap masukan Bahasa')
            return;
        }
        if(bahasa === 'indonesia'){
            whatsapp.push({
                id: whatsapp.length + 1,
                linkKirim: `https://wa.me/${no}?text=Kepada%20Yth.%0ABapak%2FIbu%2FSaudara%2Fi%0A*${linkName}*%0A%0ATanpa%20mengurangi%20rasa%20hormat%2C%0Aperkenankan%20kami%20mengundang%20Bapak%2FIbu%2FSaudara%2Fi%20*${linkName}*%20untuk%20menghadiri%20acara%20kami.%0A%0ABerikut%20link%20undangan%20kami%2C%20untuk%20info%20lengkap%20dari%20acara%20bisa%20kunjungi%3A%0A*${linkUser}*%0A%0AMerupakan%20suatu%20kebahagiaan%20bagi%20kami%20apabila%20Bapak%2FIbu%2FSaudara%2Fi%20berkenan%20untuk%20hadir%20dan%20memberikan%20doa%20restu.%0A%0ATerima%20kasih%20banyak%20atas%20perhatiannya`,
                tamu:tamu
            })
            setTimeout(() => {
                whatsapp.map(data => {
                    let url = gantiPlusDenganPercent2B(data.linkKirim)
        
                    return window.open(url, '_blank'); 
                    
                }, 1000)
                setWhatsapp([])
    
            })
        }
        if(bahasa === 'inggris'){
            whatsapp.push({
                id: whatsapp.length + 1,
                linkKirim: `https://wa.me/${no}?text=Dear.%0D%0AMr%2FMs%2FBrother%2FSister%0D%0A*${linkName}*%0D%0A%0D%0AWith%20all%20due%20respect%2C%0D%0Aplease%20allow%20us%20to%20invite%20Mr%2FMs%2FBrother%2FSister%20*${linkName}*%20to%20attend%20our%20event.%0D%0A%0D%0AHere%20is%20our%20invitation%20link%2C%20for%20complete%20information%20about%20the%20event%2C%20please%20visit%3A%0D%0A*${linkUser}*%0D%0A%0D%0AIt%20is%20a%20pleasure%20for%20us%20if%20you%20are%20willing%20to%20attend%20and%20give%20your%20blessing.%0D%0A%0D%0AThank%20you%20very%20much%20for%20your%20attention`,
                tamu:tamu
            })
            setTimeout(() => {
                whatsapp.map(data => {
                    let url = gantiPlusDenganPercent2B(data.linkKirim)
        
                    return window.open(url, '_blank'); 
                    
                }, 1000)
                setWhatsapp([])
    
            })
        }
       
        
   }
  
    const handlesubmit = (event) => {
        event.preventDefault();
        if(tamu === ''){
            alert('harap masukan nama tamu!')
            return;
        }

        if(link === ''){
            alert('harap masukan link undangan!')
            return;
        }
        setTamu('')
        let plus = tamu.replace(/ /g, "+")
        hasil.push({
            id: hasil.length + 1,
            link: `${link}?to=${plus}`,
            tamu: tamu
        })
       
        
    }


    const stylebackground = {
        backgroundImage: `url(${wedding})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }

    return(
        <>
           <div className="flex flex-col sm:flex-row md:flex-row justify-end">
                <div className="fixed left-0 flex hidden md:block lg:block items-center justify-center h-screen w-full sm:w-1/2 md:w-1/2 bg-blend-darken" style={stylebackground}>
                <div className='absolute top-96 left-1/4'>
                <h1 className='font-bold text-slate-700 text-2xl text-center text-white'>Create Nama Tamu</h1>
                <p className='text-slate-600 text-center text-white'>Silahkan isi tamu undangan yang akan anda kirimkan</p>
                </div>
                </div>
                <div className='flex w-full sm:w-1/2 md:w-1/2 items-center'>
                    <div className='w-full py-20'>
                    <h1 className='text-3xl font-bold text-center'>E-wedding.web.id</h1>
                    <form onSubmit={handlesubmit} className='flex flex-col w-full sm:px-20 px-10 sm:px-52 md:px-20 pt-20'>
                        <label className='text-slate-600 font-medium'>Link undangan:</label>
                        <label className='text-slate-600 mb-2'>contoh: https://e-wedding.web.id/anggie-aditya/ </label>
                        <input type='text' value={link} onChange={(event) => setLink(event.target.value)} className='w-full border-2 border-gray-200 ring-2 text-gray-700 rounded-md focus:bg-blue-50 focus:ring-blue-500 py-1 px-2 ring-gray-200 focus:outline-0 text-md mb-5'/>
                        <label className='text-slate-600 font-medium'>Nama Tamu:</label>
                        <label className='text-slate-600 mb-2'>contoh: suami dan istri (Tidak Boleh Pakai Simbol &) </label>
                        <input type='text' value={tamu} onChange={(event) => setTamu(event.target.value)} className='w-full border-2 border-gray-200 ring-2 text-gray-700 rounded-md focus:bg-blue-50 focus:ring-blue-500 py-1 px-2 ring-gray-200 focus:outline-0 text-md mt-2'/>
                        <button className='transition ease-in-out delay-150 hover:-translate-y-1 px-1 hover:bg-green-700 hover:text-white py-2 rounded-md bg-green-200 text-green-700 w-40 sm:w-1/3 md:w-1/3 lg:w-1/3 mt-4' type='submit'>Generate Tamu</button>
                        </form>
                        <div className='w-full'>
                        <div className='flex sm:px-20 px-10 md:px-20 lg:px-20'>
                      <ul className=''>
                      
                            <h1 className='text-xl font-bold mt-5'>Link Tamu:</h1>
                            {hasil.map(tamus => {
                                return(
                                    <>
                                    <div className='flex gap-x-2 items-center'>
                            <a href={tamus.link}>
                            
                            <li className='text-slate-600 my-2' key={tamus.id}>{tamus.id}. {tamus.link}</li></a>
                        <button onClick={() => deleteById(tamus)} className='transition ease-in-out delay-150 bg-red-200 text-red-700 hover:bg-red-700 hover:text-white rounded-md px-2 py-1'><IconTrash width="20px" height="20px"/></button>
                        <button onClick={() => detailLink(tamus.id)} className='transition ease-in-out delay-150 bg-blue-200 text-blue-700 hover:bg-blue-700 hover:text-white rounded-md px-2 py-1'><IconSend width="20px" height="20px"/></button>
                        </div>
                
                  </>
                        )
                    })}
                </ul>
                </div>
                </div>

                    </div>
                </div>

           </div>
           <Transition
        show={popup}
        enter="transition duration-500 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
          
           <div className='fixed top-0 left-0 w-full h-screen' style={{ backgroundColor: "rgba(0,0,0,0.5)" }} >
                <div className='fixed bg-white w-full sm:w-1/2 md:w-1/2 lg:w-1/2 h-screen sm:h-3/4 md:h-3/4 lg:h-3/4 rounded-md drop-shadow-md sm:top-20 md:top-20 lg:top-20 top-0 left-0 sm:left-1/4 md:left-1/4 lg:left-1/4'>
                    <button onClick={() => {setPopup(!popup)}}><IconSquareRoundedX className='absolute top-0 right-0' width="40px" height="40px"/></button>
                    <div className='p-10'>
                    {selectedData  && (
                        <>
                        <h1 className='font-bold text-2xl text-center mb-5'>Kirim Undangan Lewat Whatsapp</h1>
                        <div className='mb-5'>
                            <h1 className='font-medium text-black text-lg'>Link Undangan:</h1>
                            <input value={selectedData.link} id="linkFix" className='w-full bg-gray-100 cursor-not-allowed ring-1 text-gray-700 rounded-md py-1 px-2 ring-gray-200 focus:outline-0 text-md mt-2' readOnly></input>
                        </div>
                        <div className='mb-5'>
                            <h1 className='font-medium text-black text-lg hidden'>Nama Tamu:</h1>
                            <input value={selectedData.tamu} id="linkName" className='hidden w-full bg-gray-100 cursor-not-allowed ring-1 text-gray-700 rounded-md py-1 px-2 ring-gray-200 focus:outline-0 text-md mt-2' readOnly></input>
                        </div>
                        </>
                    )
                        
                    }
                       <div className='mb-5 hidden'>
                            <label className='font-medium text-black text-lg' name="">No Whatsapp:</label><br></br>
                            <label className='text-slate-600 mb-2'>Note: Harap Gunakan Kode Negara Dan Tidak Pakai Spasi. Contoh: (628979382175)</label>
                            <input   type='text' value={no} onChange={(e) => setNo(e.target.value)} className='w-full ring-1 text-gray-700 rounded-md focus:bg-blue-50 focus:ring-blue-500 py-1 px-2 ring-gray-200 focus:outline-0 text-md mt-2'/>
                        </div>
                        <div className='mb-5'>
                            <label className='font-medium text-black text-lg' name="">Bahasa Pesan:</label><br></br>
                            <div className='flex gap-x-3 items-center mt-2'>
                            <label className='inline-flex items-center'>
                           <input type="radio" onChange={selectBahasa} checked={bahasa === 'inggris'} value="inggris" className='form-radio text-indigo-600 border-2 border-indigo-600 bg-white rounded-full w-5 h-5 flex-shrink-0' />
                           <span className='ml-2'>Bahasa Inggris</span>
                           </label>
                           <label className='inline-flex items-center'>
                           <input type='radio' onChange={selectBahasa} checked={bahasa === 'indonesia'} value="indonesia" className='form-radio text-indigo-600 border-2 border-indigo-600 bg-white rounded-full w-5 h-5 flex-shrink-0' /><span className='ml-2'>Bahasa Indonesia</span>
                           </label>
                           </div>   
                        </div>  
                       
                        <button onClick={prosesKirim} className='flex flex-inline gap-x-3 px-4 py-2 transition ease-in-out delay-150 hover:-translate-y-1 px-1 hover:bg-green-700 hover:text-white py-2 rounded-md bg-green-200 text-green-700 w-60'><IconBrandWhatsapp />Kirim Pesan Whatsapp</button>
                      
                    </div>
                </div>
           </div>
           </Transition>
        </>
    )
 }

 export default Form;